import React from 'react'
import {Grid, Typography, useMediaQuery} from '@mui/material'
import Post from '../posts/post/Post'
import useStyles from './styles'
import Navigation from './nav/Navigation'
import { Box } from '@material-ui/core'
const Blog = ({posts,page,setPage,handlegetArtitles}) => {
   
   const classes=useStyles()
   // Define a media query for screens larger than 600px (typically desktop)
  const isDesktop = useMediaQuery('(min-width:600px)');
  React.useEffect(()=>{
  /*   const getArtitle = async()=>{
      
      return await axios.get('http://localhost:1337/api/articles?populate=*')
   }

   const handlegetArtitles = async()=>{
        getArtitles().then((response)=>{
              console.log(response)
        })
   }  
   
   handlegetArtitles() */
  })
   
  return (
    <Box >

        {
          isDesktop ? '': <Grid className={classes.title} container justifyContent={'flex-start'} padding={2}>
          <Grid item >
              <Typography color='darkgray'>Blog</Typography>
          </Grid>
       </Grid>
        }
         <Grid container justifyContent='space-between'  direction={'column'}>
       {/* <Grid item xs={0} sm={0} md={12} lg={12} >
              
        </Grid>  */}
        
        <Grid item xs={12} sm={12} md={12} lg={12} >
         
              <Grid container justifyContent={'flex-start'} height={'80vh'} overflow={'scroll'} spacing={1} className={classes.postswrapper} >
             {posts.map((post,i)=>{
                return(
                <Grid item xs={12}  key={i} >
                    <Post post={post} />
                </Grid>
                )
             })}
          </Grid>
       </Grid>
             <Grid item xs={12}>
              <Navigation page={page} setPage={setPage} handlegetArtitles={handlegetArtitles}/>
             </Grid>
          </Grid>
    </Box>
   
  )
}

export default Blog
