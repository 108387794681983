import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root:{
    cursor:'pointer',
    height:100
     /*[theme.breakpoints.down('xs')]:{
          marginBottom:'10vh', 
         padding:'2px 3px',
         height:80,
         marginBottom:2,
        }*/
  },
  image: {
    width:120,height:90,
    [theme.breakpoints.down('xs')]:{
      width:90,height:85,

      borderRadius:6,
    }
  },
 
  title: {
    fontSize:'1rem',
    [theme.breakpoints.down('xs')]:{
      fontSize:'0.7rem',
      fontWeight:500,
    }
    
  },
   meta:{
    fontSize:'0.9rem',
  },
  description:{
  fontSize:'0.7rem',
  },
  emptyButton: {
    minWidth: '150px',
    [theme.breakpoints.down('xs')]:{
      marginBottom: '5px',
    },
    [theme.breakpoints.up('xs')]: {
      marginRight: '20px',
    },
  },
  container:{
    padding:'5px'
 },
  price:{
    color:'red',
    fontWeight:500,
 },
  checkoutButton: {
    minWidth: '150px',
  },
  link:{
    textDecoration: 'none',
    color:'var(--app-primary)'
  },
  notice:{
    padding:5
  },
  cardDetails:{
    display: 'flex',
    [theme.breakpoints.down('xs')]:{
      flexDirection:'column',
      width:'100%'
    }, /*  marginTop: '1vh', */  nottom: '3vh',
    width: '100%',
    justifyContent:'space-between',
    [theme.breakpoints.up('xs')]:{
      justifyContent:'space-around',

    },
    border:'0px solid'
  },
  checkoutButtons: {
    width:'50%',
    [theme.breakpoints.down('xs')]:{
      width:'98%'
    },
    display: 'flex',
    justifyContent:'space-between',
    alignItems: 'center',
    border:'0px solid'
  },
  filledcart:{
    border:'0px solid',
    height:'65vh',
    overflow:'hidden',
    marginBottom:'2vh',
    [theme.breakpoints.down('xs')]: {
      height:'66vh',
      overflow:'scroll',
    

    },
  }
}));
